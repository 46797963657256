import Vue from 'vue'

export const state = Vue.observable({
  youtubeConsent: false,
  mapboxConsent: false,
})

export const mutations = {
  requestConsent(id, callback) {
    cookieSDK.requestConsent(id, callback)
  }
}

export default { state, mutations }

const vendors = ['MapBox', 'youtube']

function initCookieSDK(tryCount = 10) {
  if (tryCount <= 0) return

  if (window.axeptioSDK) {
    cookieSDK = window.axeptioSDK

    cookieSDK.isReady
      ? onCookieSDKReady()
      : cookieSDK.on('ready', onCookieSDKReady, { once: true })

    return
  }

  setTimeout(() => { initCookieSDK(tryCount - 1) }, 1500)
}

function onCookieSDKReady() {
  let attribut

  vendors.forEach(vendor => {
    attribut = `${vendor.toLowerCase()}Consent`
    state[attribut] = cookieSDK.hasAcceptedVendor(vendor) === true
  })

  cookieSDK.on('cookies:complete', onCookieUpdate)
}

function onCookieUpdate(choices) {
  let attribut

  vendors.forEach(vendor => {
    attribut = `${vendor.toLowerCase()}Consent`
    state[attribut] = choices[vendor]
  })
}

let cookieSDK = null

if (typeof window === 'undefined' || process.env.AXEPTIO_DISABLED) {
  state.youtubeConsent = true
  state.mapboxConsent = true
} else {
  initCookieSDK()
}

