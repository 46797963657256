
export default {
  props: {
    src: { type: String, default: null },
    poster: { type: String, default: null },
    preload: { type: String, default: 'none' },
    autoplay: { type: Boolean, default: false },
    muted: { type: Boolean, default: false },
    loop: { type: Boolean, default: false },
    playsinline: { type: Boolean, default: false },
  },

  data() {
    return {
      progress: 0,
      playing: false,
    }
  },

  watch: {
    playing(value) {
      if (value) { this.progressUpdate() }
    }
  },

  beforeDestroy() {
    cancelAnimationFrame(this.progressAnimationFrame)
  },

  methods: {
    progressUpdate() {
      const { duration, currentTime } = this.$el
      this.progress = duration ? Math.min(currentTime / duration, 1) : 0

      this.$emit('progress', this.progress)

      if (this.playing) {
        this.progressAnimationFrame = requestAnimationFrame(() => { this.progressUpdate() })
      }
    },

    setTime(progress) {
      const { duration = 0 } = this.$el
      this.$el.currentTime = progress * duration
      this.progress = progress
      this.progressUpdate()
    },

    togglePlay() {
      !this.playing ? this.$el.play() : this.$el.pause()
    },

    play() {
      this.$el.play()
    },

    pause() {
      this.$el.pause()
    },

    goFullscreen() {
      if (this.$el.webkitEnterFullscreen || this.$el.requestFullscreen) {
        this.$el.webkitEnterFullscreen()
        this.$el.requestFullscreen()
      }
    },
  }
}
