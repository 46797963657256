
import cookies from '@/assets/scripts/stores/store-cookies'

export default {
  props: {
    youtubeId: { type: String, default: null },
    autoplay: { type: Boolean, default: false },
    muted: { type: Boolean, default: false },
    loop: { type: Boolean, default: false },
    playsinline: { type: Boolean, default: false },
  },

  data() {
    return {
      allowed: false,
      playing: false,
      autoPlayAfterConsent: false,
    }
  },

  computed: {
    youtubeConsent() { return cookies.state.youtubeConsent }
  },

  mounted() {
    if (!this.youtubeConsent && this.autoplay) {
      this.$emit('waitForUserConsent')
      this.autoPlayAfterConsent = true
    }
  },

  methods: {
    play() {
      if (!this.youtubeConsent) {
        this.$emit('waitForUserConsent')
        this.autoPlayAfterConsent = true
        cookies.mutations.requestConsent('youtube', this.onCookieConsent)
        return
      }

      this.$refs.ytVideo.player.playVideo()
    },

    pause() {
      if (!this.youtubeConsent) {
        this.autoPlayAfterConsent = false
        return
      }

      this.$refs.ytVideo.player.pauseVideo()
    },

    setTime(progress) {
    },

    togglePlay() {
      if (!this.youtubeConsent) {
        this.autoPlayAfterConsent = !this.playing
        return
      }

      this.playing ? this.$refs.ytVideo.player.pauseVideo() : this.$refs.ytVideo.player.playVideo()
    },

    onCookieConsent() {
      if (this.autoPlayAfterConsent) {
        this.$nextTick(() => { this.play() })
      }
    },
  },
}
