
export default {
  name: 'VideoPlayerUI',

  props: {
    controls: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    playing: { type: Boolean, default: false },
    progress: { type: Number, default: 0 },
  },

  methods: {
    timelineClick({ offsetX: posX, currentTarget: el }) {
      this.$emit('clickTimeline', posX / el.offsetWidth)
    },
  }
}
