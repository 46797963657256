
export default {
  props: {
    src: { type: String, default: null },
    poster: { type: String, default: null },
    preload: { type: String, default: 'none' },
    preview: { type: String, default: null },
    posterPreview: { type: String, default: null },
    playButtonEnabled: { type: Boolean, default: true },
    fullscreenEnabled: { type: Boolean, default: false },
    youtubeId: { type: String, default: null },
    options: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      UIdisplayed: true,
      loading: false,
      playing: false,
      playerActive: false,
      progress: 0,
      shouldPlayInline: true,
      needUserContent: false,
    }
  },

  computed: {
    propsPreview() {
      let src = this.preview
      if (!src && this.youtubeId) src = this.src
      if (!src) return null

      return {
        src,
        poster: this.posterPreview,
        playsinline: true,
        autoplay: true,
        loop: true,
        muted: true,
      }
    },

    propsVideo() {
      return {
        src: this.src,
        poster: this.poster,
        preload: this.preload,
        autoplay: this.options.autoplay,
        muted: this.options.muted,
        loop: this.options.loop,
        playsinline: true,
      }
    },
  },

  watch: {
    playerActive(value) {
      if (!this.$refs.preview) return;
      value ? this.$refs.preview.pause() : this.$refs.preview.play();
    },

    playing(value) {
      if (value) {
        if (this.fullscreenEnabled) { this.$refs.video.goFullscreen() }
      }
    },
  },

  methods: {
    setTime(progress) {
      if (this.$refs.video) this.$refs.video.setTime(progress)
    },

    togglePlay() {
      if (this.$refs.video) this.$refs.video.togglePlay()
    },

    play() {
      if (this.$refs.video) this.$refs.video.play()
    },

    pause() {
      if (this.$refs.video) this.$refs.video.pause()
    }
  },
}
